<template>
  <div>
    <div class="offers-header has-text-centered">
      <p class="is-size-4 is-size-3-desktop has-text-weight-bold">
        {{ $t("af:mortgage_invite_offer.title") }}
      </p>
    </div>

    <div class="container">
      <div class="af-card mt-6">
        <h4>
          {{ $t("af:mortgage_invite_offer.user-welcome") }}
          {{ profile.lastName }} {{ profile.firstName }}!
        </h4>
        <p>{{ $t("af:mortgage_invite_offer.description") }}</p>
        <MortgageCancelInviteOfferModal
          :button-text="$t('af:mortgage_invite_offer.close_modal_button')"
        ></MortgageCancelInviteOfferModal>
      </div>
    </div>

    <div class="container" v-if="pageLoaded">
      <div class="af-section">
        <MortgageOfferCard
          v-for="offer in extendedOffers"
          :offer="offer"
          :key="offer.id"
          class="mb-4"
        />
        <hr class="my-3" />

        <application-template
          template="MORTGAGE_OFFER_BOTTOM"
        ></application-template>
      </div>
    </div>
  </div>
</template>

<script>
import { CUSTOM_APPEARANCE, LOCAL_STORAGE_KEYS } from "@/utils/const";
import MortgageOfferAcceptanceModal from "../components/offers/MortgageOfferAcceptanceModal";
import MortgageOfferCard from "@/components/offers/MortgageOfferCard.vue";
import { mapGetters } from "vuex";
import MortgageCancelInviteOfferModal from "@/components/offers/MortgageCancelInviteOfferModal.vue";

export default {
  name: "MortgageOffers",
  title: "af:page.mortgage_invite_offer.title",
  components: {
    MortgageCancelInviteOfferModal,
    MortgageOfferCard,
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  data() {
    return {
      pageLoaded: false,
      offerData: null,
      offer: null,
    };
  },
  async created() {
    await this.$store.dispatch("profile/fetch");

    const applicationInviteCode = localStorage.getItem(
      LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE
    );
    if (!applicationInviteCode) {
      const backPath = this.$store.getters["profile/getMortgageBackPath"];
      if (backPath === "/mortgage/invitation-offer") {
        await this.$store.dispatch("profile/updateMortgageStatus", {
          status: "REGISTERED",
          result: "SUCCESS",
        });
        await this.$router.push("/profile");
      } else {
        await this.$router.push(backPath);
      }
    }

    await this.$store.dispatch(
      "mortgageApplication/fetchInviteOffer",
      this.inviteCode
    );
    this.offerData = this.$store.getters["mortgageApplication/inviteOffer"];

    this.$store.commit(
      "offers/setAssesmentId",
      this.offerData.preliminaryAssessmentId
    );

    await this.$store.dispatch("offers/fetchOfferDatas", [
      this.offerData.offer.offerId,
    ]);

    let openOffer = this.$route.params.openOffer;
    if (openOffer === "invited") {
      this.$buefy.modal.open({
        parent: this,
        component: MortgageOfferAcceptanceModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        props: {
          offerId: this.offerData.offer.offerId,
        },
      });
      await this.$store.dispatch("profile/updateCustomerEmailConfirm", {
        offerId: null,
      });
    }

    this.pageLoaded = true;
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    ...mapGetters({
      offers: "offers/offers",
      profile: "profile/get",
    }),
    extendedOffers() {
      return this.offers.map((offer) => ({
        ...offer,
        calculation: this.offerData.offer,
      }));
    },
    inviteCode() {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.offers-header {
  background-image: url("../assets/images/background-sport-car.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 44px 0 72px;
  position: relative;

  p {
    color: white;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .retake-assessment {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;

    .text {
      color: white;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.af-card {
  padding: 20px;
  margin-top: -24px;
  background: $grey-2;

  &__filter {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .purchase-card-container {
    display: flex;
    flex-direction: column;

    .purchase-card {
      background: #ffffff;
      box-shadow: 0 2px 5px rgba(42, 45, 52, 0.1);
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;

      &-label {
        margin-bottom: 0.25rem;
        color: $grey-icon;
        text-transform: uppercase;
        font-size: 10px;
      }

      &-amount {
        font-weight: 500;
        font-size: 1.25rem;
        margin-bottom: 0;
      }
    }
  }
}

.loan-min-max {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.af-section {
  padding: 20px;
}

.required-documents {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .required-document-tile {
    padding: 26px 24px;
    background: $grey-2;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 32px 36px;

    &__filter {
      grid-gap: 2rem;
      grid-template-columns: 1fr 1fr 0.7fr;

      .loan-term-field {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: -1rem;
          height: 100%;
          border: 1px dashed #adb7c4;
        }
      }
    }
  }

  .required-documents {
    grid-template-columns: repeat(3, 1fr);
  }
}

.mbh {
  .offer-card {
    box-shadow: none;
    background: white;
    border: none;
  }

  .af-card {
    background: white;
  }

  .offers-header {
    background-image: url("../assets/images/mbh_desktop_bg.png");
  }

  .container {
    max-width: 768px;
  }
}

@media only screen and (min-width: 1024px) {
  .af-section {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .af-card {
    border-radius: 12px;

    .purchase-card-container {
      flex-direction: row;

      .purchase-card {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .sorting-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
