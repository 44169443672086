<template>
  <div>
    <span
      class="af-text-button has-text-blue has-text-weight-medium"
      @click="openModal"
    >
      {{ buttonText }}
    </span>

    <b-modal v-model="isModalOpen" :can-cancel="false">
      <div class="modal-card">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{ $t("af:mortgage_invite_offer_cancel.title") }}
        </p>
        <div class="modal-card__content">
          <div class="full-width">
            <p>{{ $t("af:mortgage_invite_offer_cancel.description") }}</p>
            <hr />
            <div class="text-center">
              <b-button
                id="offerAcceptanceModalButtonStart"
                type="is-blue"
                class="mt-4"
                @click="cancelInviteOffer"
                >{{
                  $t("af:mortgage_invite_offer_cancel.cancel-button")
                }}</b-button
              >
            </div>
          </div>
        </div>
        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEYS } from "@/utils/const";

export default {
  name: "MortgageCancelInviteOfferModal",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  beforeDestroy() {
    this.cleanup();
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    async cancelInviteOffer() {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE);
      await this.$store.dispatch("profile/updateMortgageStatus", {
        status: "REGISTERED",
        result: "SUCCESS",
      });
      await this.$router.push({ path: "user/mortgage-applications" });
      this.cancelModal();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-text-button {
  padding-left: 0 !important;
}

.text-center {
  text-align: center;
}

.modal-card {
  background: white;
  border: 1px solid $grey-lightest;
  border-radius: 24px;
  position: relative;
  max-height: 79vh;
  width: 100%;
  padding: 1rem;

  &__content {
    overflow-y: auto;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) {
  .modal-card {
    padding: 48px;
  }
}
</style>
